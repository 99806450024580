import { Tooltip } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';

import { cn } from '@/utils/theming';

export default function EngagedWorkforce({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={cn(
        'flex flex-col md:flex-row justify-between bg-blue-linear-gradient md:rounded-[20px] overflow-hidden px-4 gap-6 md:px-10 md:gap-20 items-center',
        className
      )}
    >
      <div className="row-span-1 md:my-auto py-10">
        <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">
          An engaged workforce drives results
        </h3>
        <div className="flex flex-col space-y-6 md:flex-row md:space-x-4 md:space-y-0 mt-6">
          <div className="flex flex-col gap-3">
            <div className="p-2 bg-blue-radial-gradient rounded-lg w-10 h-10">
              <Image
                src="/images/landing_v3/arrow-down.svg"
                alt="Arrow Down Icon"
                width={23}
                height={23}
              />
            </div>
            <div className="text-lg font-semibold text-gray-700">
              Turnover is up to 48% lower on teams with high engagement.
              <div className="inline-flex items-center ml-1">
                <Tooltip content="Open Link.">
                  <Link
                    href="https://www.gallup.com/workplace/351545/great-resignation-really-great-discontent.aspx"
                    target="_blank"
                  >
                    <Image
                      src="/images/landing_v3/link.svg"
                      alt="Link Icon"
                      width={16}
                      height={16}
                    />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="p-2 bg-blue-radial-gradient rounded-lg w-10 h-10">
              <Image
                src="/images/landing_v3/arrow-up.svg"
                alt="Arrow Up Icon"
                width={23}
                height={23}
              />
            </div>
            <div className="text-lg font-semibold text-gray-700">
              High engagement increases the odds of high performance by over 5X.
              <div className="inline-flex items-center ml-1">
                <Tooltip content="Open Link.">
                  <Link
                    href="https://www.gallup.com/workplace/351545/great-resignation-really-great-discontent.aspx"
                    target="_blank"
                  >
                    <Image
                      src="/images/landing_v3/link.svg"
                      alt="Link Icon"
                      width={16}
                      height={16}
                    />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative max-h-[250px] w-[312px] lg:h-[300px] lg:max-h-none lg:w-auto inset-x-0 mx-auto aspect-square bottom-0">
        <Image
          src="/images/landing_v3/engaged-workforce.png"
          alt="Engaged Workforce"
          fill
          className="object-center object-cover rounded-xl"
        />
      </div>
    </div>
  );
}
