import Image from 'next/image';

import RequestDemoButton from '@/components/RequestDemoButton';

export default function Hero() {
  return (
    <div className="container relative mx-auto min-h-[600px] px-4 pt-8 lg:pt-20">
      <div className="relative z-10 mx-auto max-w-[800px] text-center">
        <h1 className="text-center text-3xl font-semibold text-gray-900 lg:text-5xl">
          Employee engagement and recognition software reimagined
        </h1>
        <h3 className="mt-10 text-center text-2xl font-semibold text-gray-900 lg:text-3xl">
          Your AI-powered leadership platform
        </h3>
        <p className="relative mt-4 inline-block text-center text-lg text-gray-700 lg:text-xl">
          Recognize, engage, retain and attract talent with AI-powered solutions
          for organizational leaders that solve for the root cause of workforce
          issues — a lack of human connection.
        </p>
        <RequestDemoButton className="relative mt-6 w-full md:w-auto md:inline-flex px-10 h-[48px] lg:mt-[30px] " />
      </div>

      <div className="relative z-10 mx-auto h-[204px] rounded-3xl md:h-[411px] md:w-[661px] lg:h-[511px] lg:w-[892px]">
        <Image
          src="/images/landing_v3/main-hero-foreground.png"
          alt="Example image of Lebra"
          className="object-fill"
          fill
          sizes="(max-width: 1024px) 361px, 892px"
        />
      </div>
    </div>
  );
}
