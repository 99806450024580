import { Tooltip } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';

import { cn } from '@/utils/theming';

export default function EmployeeEngagement({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={cn(
        'flex flex-col-reverse md:flex-row justify-between md:rounded-[20px] overflow-hidden px-4 py-10 gap-6 md:px-10 md:gap-20 items-center',
        className
      )}
    >
      <div className="relative max-h-[250px] w-[312px] lg:h-[350px] lg:max-h-none lg:w-auto inset-x-0 mx-auto aspect-square">
        <Image
          src="/images/landing_v3/employee-engagement.png"
          alt="Employee Engagement"
          fill
          className="object-contain rounded-xl"
        />
      </div>
      <div className="row-span-1 md:my-auto">
        <h3 className="text-2xl md:text-3xl font-semibold text-gray-900">
          Employee engagement and wellbeing are suffering
        </h3>
        <div className="flex flex-col space-y-6 md:flex-row md:space-x-4 md:space-y-0 mt-6">
          <div className="flex flex-col gap-3">
            <div className="p-2 bg-blue-radial-gradient rounded-lg w-10 h-10">
              <Image
                src="/images/landing_v3/users-icon.svg"
                alt="Users Icon"
                width={23}
                height={23}
              />
            </div>
            <div className="text-lg font-semibold text-gray-700">
              Only 21% of employees believe their organization cares about their
              wellbeing.
              <div className="inline-flex items-center ml-1">
                <Tooltip content="Open Link.">
                  <Link
                    href="https://www.gallup.com/workplace/652769/despite-employer-prioritization-employee-wellbeing-falters.aspx"
                    target="_blank"
                  >
                    <Image
                      src="/images/landing_v3/link.svg"
                      alt="Link Icon"
                      width={16}
                      height={16}
                    />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="p-2 bg-blue-radial-gradient rounded-lg w-10 h-10">
              <Image
                src="/images/landing_v3/clock-icon.svg"
                alt="Clock Icon"
                width={23}
                height={23}
              />
            </div>
            <div className="text-lg font-semibold text-gray-700">
              Employee engagement reached an 11-year low in 2024.
              <div className="inline-flex items-center ml-1">
                <Tooltip content="Open Link.">
                  <Link
                    href="https://www.gallup.com/workplace/654329/workplace-challenges-2025"
                    target="_blank"
                  >
                    <Image
                      src="/images/landing_v3/link.svg"
                      alt="Link Icon"
                      width={16}
                      height={16}
                    />
                  </Link>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
